<template>
    <div class="body">
        <div class="wrapcontainer">
        <div class="card" ref="card1">
            <div class="content">
            <h2>01</h2>
            <h3 style="margin-top: 30px;">表白日记--爱心💗</h3>
            <p>2022年2月20日晚上，复习完数学之后回家，就准备了一个惊心动魄的表白计划</p>
            <a href="http://confession.hlforever.top/">点击进入</a>
            </div>
        </div>
        <div class="card" ref="card2">
            <div class="content">
            <h2>02</h2>
            <h3 style="margin-top: 30px;">一周年制作--恋爱树🌲</h3>
            <p>时间过得真快，一转眼就1年了，过来看看吧</p>
            <a href="http://christmas.hlforever.top/">点击进入</a>
            </div>
        </div>
        <div class="card" ref="card3">
            <div class="content">
            <h2>03</h2>
            <h3 style="margin-top: 30px;">3D爱心</h3>
            <p>这是在看一个电视剧的时候  突然奇想  做了一个3D爱心💗</p>
            <a href="http://love.hlforever.top/">点击进入</a>
            </div>
        </div>
        <div class="card" ref="card1">
            <div class="content">
            <h2>04</h2>
            <h3 style="margin-top: 30px;">玫瑰花</h3>
            <p>这是宝贝给我说的，做一个玫瑰花🌹嘻嘻</p>
            <a href="http://rose.hlforever.top/">点击进入</a>
            </div>
        </div>
        <div class="card" ref="card1">
            <div class="content">
            <h2>05</h2>
            <h3 style="margin-top: 30px;">第二颗圣诞树💗</h3>
            <p>2023年，这是我和宝贝一起度过的第二个圣诞节  嘻嘻</p>
            <a href="http://christmastwo.hlforever.top/">点击进入</a>
            </div>
        </div>
        <div class="card" ref="card1">
            <div class="content">
            <h2>06</h2>
            <h3 style="margin-top: 30px;">2024年过年烟花💗</h3>
            <p>过年快乐2024 我们长长久久</p>
            <a href="http://fire.hlforever.top/">点击进入</a>
            </div>
        </div>
        
        <!-- <div class="card" ref="card3">
            <div class="content">
            <h2>03</h2>
            <h3>第三个卡片</h3>
            <p>逼真的玻璃卡片悬停效果,逼真的玻璃卡片悬停效果,逼真的玻璃卡片悬停效果</p>
            <a href="#">阅读更多</a>
            </div>
        </div> -->
        </div>
    </div>
  </template>
  
  <script>
  import VanillaTilt from 'vanilla-tilt'; 
  
  export default {
    mounted() {
      VanillaTilt.init(this.$refs.card1, {
        max: 15,
        speed: 400,
        glare: true,
        "max-glare": 1
      });
      VanillaTilt.init(this.$refs.card2, {
        max: 15,
        speed: 400,
        glare: true,
        "max-glare": 1
      });
      VanillaTilt.init(this.$refs.card3, {
        max: 15,
        speed: 400,
        glare: true,
        "max-glare": 1
      });
    },
  };
  </script>
  
  <style>
  /* 样式代码 */
  /* 引入网络字体：Poppins */
@import url("http://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap");

*{
    /* 设置字体 */
    font-family: "Poppins";
}

.body{
    /* 100%的窗口高度 */
    min-height: 100vh;
    /* 弹性布局 水平+垂直居中 */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #161626;
}

/* 给背景增加两个渐变圆 */
.body::before{
    content: "";
    /* 绝对定位 */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* 渐变背景 */
    background: linear-gradient(#2193b0,#6dd5ed);
    /* 将元素剪切微一个圆形【30%表示圆的直径】【right 70%表示圆心位置】 */
    clip-path: circle(30% at right 70%);
}

.body::after{
    content: "";
    /* 绝对定位 */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* 渐变背景 */
    background: linear-gradient(#ee9ca7,#ffdde1);
    clip-path: circle(20% at 10% 10%);
}

.wrapcontainer{
    position: relative;
    /* 弹性布局 水平+垂直居中 */    
    display: flex;
    justify-content: center;
    align-items: center;
    /* 让弹性盒元素在必要的时候拆行 */
    flex-wrap: wrap;
    z-index: 1;
}

.wrapcontainer .card{
    /* 相对定位 */
    position: relative;
    height: 400px;
    width: 280px;
    background-color: rgba(255, 254, 255,0.1);
    margin: 30px;
    border-radius: 15px;
    /* 阴影 */
    box-shadow: 20px 20px 50px rgba(0, 0, 0,0.5);
    /* 溢出隐藏 */
    overflow: hidden;
    /* 弹性布局 水平+垂直居中 */    
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(255, 254, 255,0.5);
    border-left: 1px solid rgba(255, 254, 255,0.5);
    /* 背景模糊 */
    backdrop-filter: blur(5px);
}

.wrapcontainer .card .content{
    padding: 20px;
    text-align: center;
    /* 默认下移+隐藏 */
    transform: translateY(100px);  
    opacity: 0;  
    transition: 0.5s;
}

.wrapcontainer .card:hover .content{
    /* 鼠标移入，上移+显示 */
    transform: translateY(0);    
    opacity: 1;
}

.wrapcontainer .card .content h2{
    position: absolute;
    top: -80px;
    right: 25px;
    font-size: 128px;
    color: rgba(255, 254, 255,0.5);
}

.wrapcontainer .card .content h3{
    font-size: 28px;
    color: #fff;
}

.wrapcontainer .card .content p{
    font-size: 16px;
    color: #fff;
    font-weight: 300;
    margin: 10px 0 15px 0;
}

.wrapcontainer .card .content a{
    position: relative;
    display: inline-block;
    padding: 8px 20px;
    margin-top: 15px;
    background-color: #fff;
    color: #000;
    text-decoration: none;
    border-radius: 20px;
    font-weight: 500;
    box-shadow: 0 5px 15px rgba(0, 0, 0,0.2);
}
/* 这里要让玻璃效果更加逼真，需要用到一个JS库：vanilla-tilt.js */
/* 我事先下载好了，需要这个JS库的可以找我拿 */
/* 看操作~ */
  </style>
  
<!-- <template>
    <div id="wrap">
        <div class="wraptile">
                <HeaderTar></HeaderTar>
                <div class="wrap_box" @click="handld" >
                    <img src="../assets/img/backimg.jpg" alt="背景">
                    <div class="wrap_box_text">
                        <span>一周年</span>
                        <p>相爱一周年</p>
                    </div>
                </div>
                <div class="wrap_box_two" @click="handld_two" >
                    <img src="../assets/img/backimg.jpg" alt="背景">
                    <div class="wrap_box_text">
                        <span>表白</span>
                        <p>惊心动魄</p>
                    </div>
                </div>
            </div>
    </div>
    
</template>
<script>
import HeaderTar from '@/components/HeaderTar.vue'
export default {
    name: 'AlittleView',
    data() {
      return {
        
      }
    },
    components:{
        HeaderTar
    },
    methods:{
        handld(){
            window.location.href = 'http://love.handlforever.cn/'
        },
        handld_two(){
            window.location.href = 'http://first.handlforever.cn/'
        }
    }
}
</script>
<style>
#wrap{
    width: 90%;
    margin: 0 auto;
}
.wraptile{
    position: absolute;
}
.wrap_box{
    box-sizing: border-box;
    width: 180px;
    height: 280px;
    background: #fff;
    box-shadow: 1px;
    border-radius: 10px;
    margin-top: 10rem;
}
.wrap_box_two{
    position: absolute;
    top: 10rem;
    left: 15rem;
    box-sizing: border-box;
    width: 180px;
    height: 280px;
    background: #fff;
    box-shadow: 1px;
    border-radius: 10px;
}
.wrap_box_two >img,
.wrap_box > img{
    width: 100%;
    height: 200px;
    border-radius: 10px 10px 0 0;
}
.wrap_box > .wrap_box_text{

}
.wrap_box_two > .wrap_box_text p,
.wrap_box_two > .wrap_box_text span,
.wrap_box > .wrap_box_text p,
.wrap_box > .wrap_box_text span{
    font-size: 10px;
}
.wrap_box_two > .wrap_box_text span,
.wrap_box > .wrap_box_text span{
    color: #ef475d;
}

</style> -->