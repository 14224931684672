<template>
    <!-- <div>
        <img src="@/assets/img/back-img.jpg" alt="">
    </div> -->
    <div class="bg-wrap">
        <div class="bg-img">
            <div class="central central-800">
            <div class="middle animated bounce  ">
                <el-col :span="10">
                    <div class="grid-content bg-purple left">
                        <img :src="srcImg2" alt="">
                        <span>何先生</span>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="grid-content bg-purple-light love-icon">
                        <img :src="srcImg3" alt="">
                    </div>
                </el-col>
                <el-col :span="10">
                    <div class="grid-content bg-purple right">
                        <img :src="srcImg1" alt="">
                    <span>何先生的乖乖</span>
                    </div>
                </el-col>
            </div>
        </div>
        <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
            <defs>
                <path id="gentle-wave"
                      d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"/>
            </defs>
            <g class="parallax">
                <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7"/>
                <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)"/>
                <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)"/>
                <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff"/>
            </g>
        </svg>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{

            scrollTop:'',
            srcImg1:require("@/assets/img/head01.jpeg"),
            srcImg2:require("@/assets/img/head02.jpeg"),
            srcImg3:require("@/assets/img/like.svg")
        }
        
    },
    methods:{
        listenerFunction() {
            document.addEventListener('scroll', this.handleScroll, true);
        },
        handleScroll (event) {
            console.log("滚动条高度为：",event.target.scrollTop)
            this.scrollTop=event.target.scrollTop
        }
    },
    mounted(){
        this.$nextTick(() =>{
            this.listenerFunction();

      })
},
beforeDestroy () {
        document.removeEventListener("scroll", this.listenerFunction);
    }
    // window.onscroll = function () {
    //     var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    //     if (scrollTop > 500) {
    //         $('.wenan').css({
    //             'color': '#707070'
    //         });
    //         $('.alogo').css({
    //             'color': '#707070'
    //         });
    //     }

    //     if (scrollTop < 500) {
    //         $('.wenan').css({
    //             'color': '#fff'
    //         });
    //         $('.alogo').css({
    //             'color': '#fff'
    //         });
    //     }
    // }
}
</script>
<style>
.bg-wrap{
    width: 1455px;
    height: 573px;
    background: url(@/assets/img/back-img.png) no-repeat center/cover;
}
.bg-img{
    position: relative;
    width: 1455px;
    height: 573px;
}
.bg-img::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAKUlEQVQImU3IMREAIAgAwJfNkQCEsH8cijjpMf6vnXlQaIiJFx+omEBfmqIEZLe2jzcAAAAASUVORK5CYII=);
}
.central-800 {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    width: 780px;
    height: 273px;
    margin: 2rem auto;
    padding: 1rem;
    box-sizing: border-box;
}
.bg-img .middle {
    display: flex;
    align-items: center;
    justify-content: space-around;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    background: #ffffff1f;
    padding: 3rem 5rem 2rem;
    border-radius: 4rem;
}
.animated {
    animation-duration: 0.5s;
    animation-fill-mode: both;
}
.bounce {
    -webkit-animation-name: bounce;
    -webkit-transform-origin: center bottom;
    animation-name: bounce;
    transform-origin: center bottom;
}
.bg-img .middle .right img,
.bg-img .middle .left img {
    width: 10rem;
    height: 10rem;
    border-radius: 10rem;
    border: 0.2rem solid #fff;
}
.bg-img .middle .love-icon img {
    width: 7rem;
    height: 7rem;
    border: none;
    animation: img 2s linear infinite;
    animation-timing-function:ease;
}
@keyframes img{
    0% {
        
        transform: scale(0.8);
    }

    50% {
        animation-duration:0.5s;
        transform:  scale(1.3);
    }

    100% {
        animation-duration:1.5s;
        transform: scale(0.8);
    }
}
.bg-img  span {
    display: block;
    text-align: center;
    font-size: 1.5rem;
    margin-top: 1rem;
    color: #fff;
    font-family: 'Noto Serif SC', serif;
    font-weight: 700;
}
.el-row {
    margin-bottom: 20px;
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }

  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }

  .bg-img .waves {
    position: relative;
    top: 26.04rem;
    width: 100%;
    height: 5rem;
    margin-top: 5rem;
    margin-bottom: -7px;
}
  .bg-img .parallax > use {
    animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
  }
  .bg-img .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }
  .bg-img .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
  }
  .bg-img .parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
  }
  .bg-img .parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
  }
  @keyframes move-forever {
    0% {
      transform: translate3d(-90px, 0, 0);
    }
    100% {
      transform: translate3d(85px, 0, 0);
    }
  }
</style>
