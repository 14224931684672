<template>
    <div id="header-wrap">
        <div class="word">
          <span class="wenan">
            {{ wenben }}
          </span>
        </div>
        <div class="logo">
            <h1>
              <img :src=my_img alt="">
              <!-- <a class="alogo" href="#">
                {{ login_put }}
              </a> -->
            </h1>
            <p @click="sign_out">退出</p>
        </div>
    </div>
</template>
<script >
export default {
  name: 'HeaderTar',
  data() {
    return {
      login_put:"欢迎我的小可爱～",
      wenben:"喜欢花 喜欢浪漫 喜欢你.~",
      my_img:require('@/assets/img/my_img.png')
    }
  },
  methods:{
    sign_out(){
      this.$router.push("/")
    }
  },
  // props: {
  //   msg: String
  // },
  components: {
    // HelloWorld
  }
}
</script>
<style scoped>
#header-wrap{
    width: 100%;
    height: 4.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    background: #ffffff1f;
    color: #707070;
}

.logo{
  width: 50%;
  display: flex;
  justify-content:right;
  padding-right: 20px;
}
.logo p{
  padding-top: 8px;
  cursor: pointer;
  color: #fff;
}
.logo h1{
  padding-right: 10px;
}
img{
  widows: 30px;
  height: 30px;
  border: 1px solid green;
  border-radius: 50%;
}
.word{
  width: 50%;
  /* background: #fff; */
}
.wenan {
    color: #fff;
    transition: all 0.2s linear;
}
.word{
  display: flex;
  padding-left: 40px;
}

</style>
