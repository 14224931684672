<template>
  <div class="home">
    <!-- <HelloWorld msg=""/> -->
    <HeaderTar></HeaderTar>
    <IndexContent></IndexContent>
    <TextContent></TextContent>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import HeaderTar from '@/components/HeaderTar.vue'
import IndexContent from '@/components/IndexContent.vue';
import TextContent from '@/components/TextContent.vue';

export default {
  name: 'HomeView',
  data() {
    return {
      count: 0
    }
  },
  components: {
    // HelloWorld
    HeaderTar,
    IndexContent,
    TextContent
  }
}

</script>
<style>
</style>
