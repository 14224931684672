var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body"},[_c('div',{staticClass:"wrapcontainer"},[_c('div',{ref:"card1",staticClass:"card"},[_vm._m(0)]),_c('div',{ref:"card2",staticClass:"card"},[_vm._m(1)]),_c('div',{ref:"card3",staticClass:"card"},[_vm._m(2)]),_c('div',{ref:"card1",staticClass:"card"},[_vm._m(3)]),_c('div',{ref:"card1",staticClass:"card"},[_vm._m(4)]),_c('div',{ref:"card1",staticClass:"card"},[_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('h2',[_vm._v("01")]),_c('h3',{staticStyle:{"margin-top":"30px"}},[_vm._v("表白日记--爱心💗")]),_c('p',[_vm._v("2022年2月20日晚上，复习完数学之后回家，就准备了一个惊心动魄的表白计划")]),_c('a',{attrs:{"href":"http://confession.hlforever.top/"}},[_vm._v("点击进入")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('h2',[_vm._v("02")]),_c('h3',{staticStyle:{"margin-top":"30px"}},[_vm._v("一周年制作--恋爱树🌲")]),_c('p',[_vm._v("时间过得真快，一转眼就1年了，过来看看吧")]),_c('a',{attrs:{"href":"http://christmas.hlforever.top/"}},[_vm._v("点击进入")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('h2',[_vm._v("03")]),_c('h3',{staticStyle:{"margin-top":"30px"}},[_vm._v("3D爱心")]),_c('p',[_vm._v("这是在看一个电视剧的时候 突然奇想 做了一个3D爱心💗")]),_c('a',{attrs:{"href":"http://love.hlforever.top/"}},[_vm._v("点击进入")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('h2',[_vm._v("04")]),_c('h3',{staticStyle:{"margin-top":"30px"}},[_vm._v("玫瑰花")]),_c('p',[_vm._v("这是宝贝给我说的，做一个玫瑰花🌹嘻嘻")]),_c('a',{attrs:{"href":"http://rose.hlforever.top/"}},[_vm._v("点击进入")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('h2',[_vm._v("05")]),_c('h3',{staticStyle:{"margin-top":"30px"}},[_vm._v("第二颗圣诞树💗")]),_c('p',[_vm._v("2023年，这是我和宝贝一起度过的第二个圣诞节 嘻嘻")]),_c('a',{attrs:{"href":"http://christmastwo.hlforever.top/"}},[_vm._v("点击进入")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('h2',[_vm._v("06")]),_c('h3',{staticStyle:{"margin-top":"30px"}},[_vm._v("2024年过年烟花💗")]),_c('p',[_vm._v("过年快乐2024 我们长长久久")]),_c('a',{attrs:{"href":"http://fire.hlforever.top/"}},[_vm._v("点击进入")])])
}]

export { render, staticRenderFns }