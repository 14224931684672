<template>
    <div class="containerWrap">
      <div
        v-for="(panel, index) in panels"
        :key="index"
        :class="[{ 'panel': true, 'active': panel.isActive }]"
        :style="`background-image:url('${panel.image}')`"
        @click="activatePanel(panel)"
      >
        <h3 @click="handlePage(panel)">{{ panel.title }}</h3>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ItineraryView',
    data() {
      return {
        panels: [
          {
            isActive: true,
            image:
              'http://image.hlforever.top/qingdao.jpg',
            title: '胶东明珠--青岛',
          },
          {
            isActive: false,
            image:
              'http://image.hlforever.top/chengdu.jpg',
            title: '天府之国--成都',
          },
          {
            isActive: false,
            image:
              'http://image.hlforever.top/ganzi.jpg',
            title: '雪域高原--甘孜',
          },
          {
            isActive: false,
            image:
              'http://image.hlforever.top/jinans.jpg',
            title: '芙蓉泉城--济南',
          },
          {
            isActive: false,
            image:
              'http://image.hlforever.top/zibo.jpg',
            title: '烧烤小炉--淄博',
          },
          {
            isActive: false,
            image:
              'http://image.hlforever.top/beijing.jpg',
            title: '繁华京城--北京',
          },
          {
            isActive: false,
            image:
              'http://image.hlforever.top/jqqd.jpg',
            title: '敬请期待...',
          },
        ],
      };
    },
    methods: {
        handlePage(selectedPanel){
            console.log("selectedPanel",selectedPanel)
        },
        activatePanel(selectedPanel) {
            // 设置选定的面板为活动状态
            this.panels.forEach((panel) => {
                panel.isActive = panel === selectedPanel;
                });
            },
        },
  };
  </script>
<style>
/* @import url('https://fonts.googleapis.com/css?family=Muli&display=swap'); */

/* 初始化 */
.containerWrap{
    box-sizing: border-box;
    padding-top:50px ;
    display: flex;
    height:45.9rem ;
    /* width: 90vw; */
}

.panel{
    /* 跟封面一样大小 */
    background-size: cover;
    /* 把封面图片居中 */
    background-position: center;
    background-repeat: no-repeat;
    height: 80vh;
    /* 边框圆角 */
    border-radius: 50px;
    color: #fff;
    /* 鼠标放上变小手 */
    cursor: pointer;
    flex: 0.5;
    margin: 10px;
    /* 相对定位 */
    position: relative;
    -webkit-transition: all 700ms ease-in;
}

.panel h3{
    font-size: 24px;
    /* 绝对定位 */
    position: absolute;
    bottom: 20px;
    left: 20px;
    margin: 0;
    opacity: 0;
}

.panel.active{
    flex: 5;
}

.panel.active h3 {
    opacity: 1;
    transition: opacity 0.3s ease-in 0.4s;
}

@media (max-width:480px){
    .container{
        width: 100vw;
    }
    .panel:nth-of-type(4),
    .panel:nth-of-type(5){
        display: none;
    }
}

</style>