<template>
    <div id="pjax-container">
    <!-- 时间区域 -->
    <div class="time">
        <span id="span_dt_dt">这是我们一起走过的</span>
        <b id="tian">{{ timeArry.dayDiff }}</b>天
        <b id="shi">{{ timeArry.hours  }}</b>时
        <b id="fen">{{ timeArry.minutes  }}</b>分
        <b id="miao">{{ timeArry.seconds  }}</b>秒
    </div>
    <!-- 卡片区域 -->
    <div class="card-wrap">
        <div class="row central1 card-margin">
            <div class="card col-lg-4 col-sm-12 col-sm-x-12 flex-h" @click="tolink(data[0].src)">
                <img src="@/assets/img/diandian.png" alt="">
                <div class="text">
                    <span><a target="_self" href="#">{{ data[0].name }}</a></span>
                    <p>{{ data[0].text }}</p>
                </div>
            </div>
            <div class="card col-lg-4 col-sm-12 col-sm-x-12 flex-h" @click="tolink(data[1].src)">
                <img src="@/assets/img/liuyan.png" alt="">
                <div class="text">
                    <span><a target="_self" href="#">{{ data[1].name }}</a></span>
                    <p>{{ data[1].text }}</p>
                </div>
            </div>
            <div class="card col-lg-4 col-sm-12 col-sm-x-12 flex-h" @click="tolinks(data[2].src)">
                <img src="@/assets/img/about.png" alt="">
                <div class="text">
                    <span><a target="_self" href="#">{{ data[2].name }}</a></span>
                    <p>{{ data[2].text }}</p>
                </div>
            </div>
            <div class="row card-position">
              <div class="card card-b col-lg-6 col-12 col-sm-12 flex-h card-b-wid" @click="tolinks(data[3].src)">
                    <img src="@/assets/img/loveimg.png" alt="">
                    <div class="text">
                        <span><a target="_self" href="#">{{ data[3].name }}</a></span>
                        <p>{{ data[3].text }}</p>
                    </div>
                </div>
                <div class="card card-b col-lg-6 col-12 col-sm-12 flex-h card-b-wid" @click="tolink(data[4].src)">
                    <img src="@/assets/img/xinf.png" alt="">
                    <div class="text">
                        <span><a target="_self" href="#">{{ data[4].name }}</a></span>
                        <p>{{ data[4].text }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { formatDate } from "@/assets/js/month.js";
export default{
    data(){
        return{
          data:[
            {
              name:"点点滴滴",
              text:"记录你我生活",
              src:"/little"
            },
            {
              name:"旅行日记",
              text:"与你走遍大好河山",
              src:"/travel"
            },
            {
              name:"关于我们",
              text:"我们的经历",
              src:"http://photo.hlforever.top/"
            },
            {
              name:"Love Photo",
              text:"恋爱相册 记录最美瞬间",
              src:"http://threedphoto.hlforever.top/"
            },
            {
              name:"Love List",
              text:"恋爱列表 你我之间的约定",
              src:"/list"
            },
          ],
          days:"",
          times:"" ,
          minutes:"" ,
          seconds:"",
          timeArry:""
        }
    },
    created() {
    this.timeArry = formatDate('2022/02/21',new Date());
    this.timeStart();
  },
    mounted() {
      // this.timer = setInterval(() => {
      //   this.setNowTimes();
      // }, 1000);
  },
  methods:{
    timeStart() {
      // 设置定时器
      this.timer = setInterval(() => {
        this.timeArry = formatDate('2022/02/21',new Date());
      }, 1000);
  },
  tolinks(src){
    window.location.href = src
  },
  tolink(src){
    this.$router.push(`${src}`)
  }
}
}
</script>
<style>
  #pjax-container{
    margin-top:3px ;
    width: 100%;
    height: 40rem;
    background-image: linear-gradient(to right, rgba(37, 82, 110, 0.1) 1px, transparent 1px), linear-gradient(to bottom, rgba(37, 82, 110, 0.1) 1px, transparent 1px);
    background-size: 1.5rem 1.5rem;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .time {
    /*padding: 4rem 0;*/
    text-align: center;
    font-size: 1.8rem;
    letter-spacing: 0.2rem;
  }
  .time span {
    font-size: 1.8rem;
    line-height: 5rem;
    display: block;
    background-image: linear-gradient(270deg, #ff4500, #ffa500, #ffd700, #90ee90, #00ffff, #1e90ff, #9370db, #ff69b4, #ff4500);
    -webkit-background-clip: text;
    color: #0000;
    animation: jianbian 60s linear infinite;
    font-family: 'Noto Serif SC', serif;
    font-weight: 400;
}
  .time b {
    font-size: 3rem;
    font-family: 'Noto Serif SC', serif;
    font-weight: 700;
  }
  @keyframes jianbian {
    to {
      background-position: -2000rem;
    }
  }
  @keyframes img {
         0% {
            transform:scale(0.8, 0.8);
         }
    70%{
       transform:scale(1.3, 1.3);
    }
         100% {
           transform:scale(0.8, 0.8);
         }
     }
  .central1 {
    width: 1180px;
    /* height: 1000px; */
    margin: 2rem auto;
    padding: 1rem;
    box-sizing: border-box;
  }
  .row {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1.8rem;
}
  .card-position{
    position: relative;
  }
  .card-wrap .row .card {
  padding: 2rem;
  border-radius: 1.5rem;
  background: #fff;
  box-shadow: 0 8px 12px #ebedf0;
  border: 1px solid rgba(208, 206, 206, 0.4) !important;
}
.card-wrap .row .card:hover {
  box-shadow: 0 6px 10px #e9e9e9;
  cursor: pointer;
  transform: translateY(-6px);
  cursor:url(../assets/img/cur/hover.cur), pointer;
}
.card-wrap .row .card img {
  width: 5rem;
  height: 5rem;
  margin-right: 2rem;
  border-radius: 5rem;
}
.card-wrap .row .card .text {
  flex-grow: 1;
  font-family: 'Noto Serif SC', serif;
  font-weight: 700;
}
.card-wrap .row .card .text span {
  font-size: 1.8rem;
  line-height: 3rem;
  letter-spacing: 0.2rem;
}
.card-wrap .row .card .text p {
  font-size: 1.1rem;
  letter-spacing: 0.3rem;
  color: #777;
}
.card-wrap .row .flex-h {
  width: 259px;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  transition: all 0.08s linear;

}
.card-wrap .card-b {
  margin: 0 auto;
  width: 80%;
  padding: 1.5rem;
  border-radius: 2rem;
  background: #fff;
  box-shadow: 0 8px 12px #ebedf0;
  border: 1px solid rgba(208, 206, 206, 0.4) !important;
  font-family: 'Noto Serif SC', serif;
  font-weight: 700;
}
.card-wrap .card-b img {
  width: 8rem;
  margin-right: 5rem;
}
.card-wrap .card-b .text {
  flex-grow: 2;
}
.card-wrap .card-b .text span {
  font-size: 2rem;
  line-height: 5rem;
}
.card-wrap .card-b .text p {
  font-size: 1.2rem;
  color: #777;
}
.footer-warp {
  margin-top: 4rem;
  background: #f8f8f8;
}
.footer-warp .footer {
  padding: 2rem;
  text-align: center;
  font-family: 'Noto Serif SC', serif;
  font-weight: 400;
}
.footer-warp .footer p {
  line-height: 2.5rem;
}
.bg-img:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAKUlEQVQImU3IMREAIAgAwJfNkQCEsH8cijjpMf6vnXlQaIiJFx+omEBfmqIEZLe2jzcAAAAASUVORK5CYII=);
}
.card-position{
  position: relative;
  top: 187px;
  right: 64rem;
}
.card-margin{
  position: absolute;
  left: 9.5rem;
}
.card-b-wid{
  width: 25rem !important;
  height: 7rem !important;
}
</style>
