import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/loading.css'
import './assets/css/index.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import 'https://fonts.googleapis.com/css2?family=Noto+Serif+SC:wght@700&display=swap'
// import 'https://fonts.googleapis.com/css2?family=Noto+Serif+SC:wght@400&display=swap';

Vue.config.productionTip = false
Vue.use(ElementUI);

// 全局前置守卫

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
