import { render, staticRenderFns } from "./HeaderTar.vue?vue&type=template&id=187a1590&scoped=true&"
import script from "./HeaderTar.vue?vue&type=script&lang=js&"
export * from "./HeaderTar.vue?vue&type=script&lang=js&"
import style0 from "./HeaderTar.vue?vue&type=style&index=0&id=187a1590&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "187a1590",
  null
  
)

export default component.exports