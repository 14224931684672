// 时间差计算
export function formatDate(beginTime, endTime) {
      var dateBegin = new Date(beginTime);
      var dateEnd = new Date(endTime);
      var dateDiff = dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
      var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
      var leave1 = dateDiff % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000)); //计算出小时数
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000)); //计算相差分钟数
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);
      function check(num) {
        if (num < 10) {
          // 如果数字小于10,前边拼接个0
          return "0" + num;
        } else {
          return num;
        }
      }
    let timeArry = {};
    timeArry.dayDiff = check(dayDiff)
    timeArry.hours = check(hours)
    timeArry.minutes = check(minutes)
    timeArry.seconds = check(seconds)
    return timeArry
    }